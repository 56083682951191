import { setServerUrl } from '@giphy/js-fetch-api'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'

export const isBuildStep = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD

export const serverRuntimeConfig = {
    giphyHost: process.env.GIPHY_HOST || '',
    apiKey: process.env.GIPHY_DESKTOP_API_KEY || '',
    mobileApiKey: process.env.GIPHY_MOBILE_API_KEY || '',
    vwoEnabled: process.env.GIPHY_VWO_ENABLED === 'true',
    containerBuildEnv: process.env.GIPHY_SECRETS_CONTAINER_BUILD_ENV || '',
    manifestBuildEnv: process.env.GIPHY_CONFIGMAP_CONTAINER_BUILD_ENV || '',
    defaultClientCountryCode: process.env.GIPHY_DEFAULT_CLIENT_COUNTRY_CODE || '', // Dev, not behind Fastly, where X-Client-Country-Code is not provided
    logLevel: process.env.GIPHY_WEB_LOG_LEVEL,
}

export const publicRuntimeConfig = {
    fourOhFourApiKey: process.env.NEXT_PUBLIC_GIPHY_FE_FOUR_O_FOUR_API_KEY || '',
    pingbackUrl: process.env.NEXT_PUBLIC_GIPHY_PINGBACK_URL || '',
    apiUrl: process.env.NEXT_PUBLIC_GIPHY_API_URL || '',
    apiKey: process.env.NEXT_PUBLIC_GIPHY_FE_WEB_API_KEY || '',
    mobileApiKey: process.env.NEXT_PUBLIC_GIPHY_FE_MOBILE_API_KEY || '',
    taggerApiKey: process.env.NEXT_PUBLIC_GIPHY_FE_TAGGER_API_KEY || '',
    gtmContainerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || '',
    serviceGatewayUrl: process.env.NEXT_PUBLIC_GIPHY_SERVICE_GATEWAY_URL || '',
    storiesApiKey: process.env.NEXT_PUBLIC_GIPHY_FE_STORIES_AND_GIPHY_TV_API_KEY || '',
    adsEnabled: process.env.NEXT_PUBLIC_GIPHY_ADS_ENABLED === 'true',
    ddVersion: (process.env.NEXT_PUBLIC_DD_VERSION || 'unknown').toLowerCase().substring(0, 200),
}

setServerUrl(publicRuntimeConfig.apiUrl!)

function check(value: string | boolean | undefined, key: string, env: string) {
    if (value === undefined) {
        const message = `"${key}" is missing from the ${env} env vars, check env.ts file for env variable name`
        console.error(message)
        throw new Error(message)
    }
}
const requiredServerKeys: (keyof typeof serverRuntimeConfig)[] = ['giphyHost', 'apiKey', 'mobileApiKey']
// don't check server env variables during build step
if (!isBuildStep && typeof window === 'undefined') {
    requiredServerKeys.forEach((key) => {
        check(serverRuntimeConfig[key], key, 'server')
    })
}

// these need to be available at build time
const requiredPublicKeys: (keyof typeof publicRuntimeConfig)[] = [
    'pingbackUrl',
    'apiUrl',
    'apiKey',
    'mobileApiKey',
    'fourOhFourApiKey',
    'ddVersion',
]

requiredPublicKeys.forEach((key) => {
    check(publicRuntimeConfig[key], key, 'build')
})

// canonical host for current environment
// e.g. prod: giphy.com, dev: web-feature-spin-web.giphy.tech, local: local.giphy.com:8000
export const giphyUrl = isBuildStep
    ? '' // we don't have access to GIPHY_HOST in the build step
    : process.env.GIPHY_HOST // on the server we use the env variable
    ? `${process.env.GIPHY_PROTOCOL || 'https://'}${process.env.GIPHY_HOST}` // GIPHY_PROTOCOL is only set in .env.development
    : location.origin // otherwise use location origin

// use this to make calls to local django container from server-side,
// or relative to current host from client-side
export const giphyLocalUrl = isBuildStep
    ? ''
    : process.env.GIPHY_PROTOCOL // GIPHY_PROTOCOL indicates local environment
    ? `${process.env.GIPHY_PROTOCOL}${process.env.GIPHY_HOST}`
    : process.env.GIPHY_HOST
    ? 'http://127.0.0.1' // server-side in container
    : location.origin // client-side has location origin

export type DeviceType = 'mobile' | 'desktop'
